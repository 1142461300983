<template>
    <main id="formView" class="container">
        <div class="content">
            <div class="content_head">
                <div class="inner">
                    <div class="breadcrumb">
                        <ul>
                            <li><a href="#" class="ico_home" title="Home"></a></li>
                            <li>{{boardInfoVO.boardName}}</li>
                        </ul>
                    </div>
                    <h2 class="page_title">{{boardInfoVO.boardName}}</h2>
                </div>
            </div>
            <div class="content_body">
                <div class="inner">
                    <div class="board_view">
                        <div class="view_head">
                            <h3 class="tit" v-text="boardVO.title"></h3>
                            <p class="date">{{boardVO.createDate | dateFilter  }}</p>
                        </div>
                        <div class="view_body" >
                            <div v-html="boardVO.contentHtml"></div>
                            <ul class="attach_list">
                                <li v-for="(files, index) in boardVO.attachList" :key="'ff-' + index">
                                    <a href="#" @click="doDownload(files.fidx)">{{files.filename}}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="view_foot">
                            <ul class="related_list" >
                                <li class="related_item" :class="prevBoardVO || 'disable'">
                                    <a @click="goView(prevBoardVO)">
                                        <span class="prev">이전글</span>
                                        <strong class="tit">{{prevBoardVO?.title ||'이전글이 없습니다.' }}</strong>
                                    </a>
                                </li>
                                <li class="related_item" :class="nextBoardVO  || 'disable'">
                                    <a @click="goView(nextBoardVO)">
                                        <span class="next">다음글</span>
                                        <strong class="tit">{{nextBoardVO?.title ||'다음글이 없습니다.' }}</strong>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="button_area">
                            <button v-if="this.boardVO.writerKey == this.getLoginId()" @click="goEdit(boardVO)" type="button" class="btn_edit btn_primary">수정</button>
                            <button type="button" class="btn_list btn_black" @click="moveList()">목록</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>



    export default {
        name: "formView",
        data(){

            return {
                boardVO: new this.BoardVO(this.getSessionProc()),
                prevBoardVO:{},
                nextBoardVO:{},
                boardInfoVO: {},
            }
        },
        created() {

        },
        mounted() {
            this.initForm()
            this.getBoard()
        },
        methods:{

            async initForm(){
                this.boardInfoVO = await this.getBoardInfo(this.$route.params.boardKey)

            },
            boardBack(){
              history.back()
            },

            goView( _item ) {
                if (_item == null) return


                if (this.boardInfoVO.boardKey == 'cms_board'){
                    this.goReviewBoard( _item.boardKey, _item.idx )
                }else{
                    this.goAppBoard( _item.boardKey, _item.idx )
                }

                this.$emit("goBoardOne", _item.idx);
            },

            getBoard () {

                this.$eventBus.$emit("startProgress", 2)
                this.axiosCaller.get(this, this.APIs.BOARDS + "/getBoardOne/" + this.$route.query.idx, {}, (res) => {
                    this.$eventBus.$emit("doneProgress", 2)

                    console.log("data==",res)
                    var result = res.data
                    this.boardVO = result.boardVO

                    if (this.boardVO.attachList.length > 0) {
                        this.fileList = result.boardVO.attachList;
                    }

                    this.prevBoardVO = result.prev
                    this.nextBoardVO = result.next

                    // /**
                    //  * 로그인 사용자의 글인지 확인
                    //  */
                    // if ( this.boardVO.writerKey !== this.getLoginId() ) {
                    //     this.swalUtils.gritter("error", "본인이 작성한 글이 아닙니다.", "error", 800)
                    //         .then(() => {
                    //             history.back();
                    //         })
                    //     return
                    // }

                })

            },
            //수정
            goEdit(){

                // location.href="/review/"+this.boardInfoVO.boardKey +"/form" + '?idx=' + this.boardVO.idx
                this.webUtils.goTo(this, {path: "/review/"+this.boardInfoVO.boardKey +"/form" + '?idx=' + this.boardVO.idx})
                this.$emit("goBoardOne", this.boardVO.idx);

            },
            moveList(){

                if (this.boardInfoVO.boardKey == 'cms_board'){
                    location.href = "/review/"+this.boardInfoVO.boardKey
                }else{
                    location.href = "/board/"+this.boardInfoVO.boardKey
                }

            },
            doDownload(fidx) {
                // if (this.appDevice() === "IOS") {
                //     webkit.messageHandlers.iOSFileDownload.postMessage(APIs.BOARD_FILES_DOWN + '/' + fidx)
                //     return;
                // }
                location.href = this.APIs.BOARD_FILES_DOWN + '/' + fidx
            }

        }
    }
</script>

<style scoped>
	.related_list {
		cursor: pointer;
	}
</style>